<template>
    <div class="ctx-welcome-view">
    <img :src="img" alt="item" />
    <h4 class="text-center">Congratulations! you have created your channel with your gmail</h4>
    <p>You can use your gmail address with connectxone now</p>
   
  </div>
</template>

<script>
import {
  emailAccountComputed,
  emailAccountMethods,
} from "../../../state/helpers/eaccount";
import { inboxMethods } from "../../../state/helpers/inbox";
import { communicationChannelComputed, communicationChannelMethods } from "../../../state/helpers/communicationchannel";
import Welcome from "../../../assets/images/auth/verify.gif"
export default {
   data(){
    return {
      img:Welcome,
    }
  },
  computed: {
    ...emailAccountComputed,
    ...communicationChannelComputed,
  },
  methods: {
    ...emailAccountMethods,
    ...inboxMethods,
    ...communicationChannelMethods,
    loadEmailAccounts(gmail_code) {
      const fullname = localStorage.getItem("fullname");
      const account_title = localStorage.getItem("account_title");
      const icon_color = localStorage.getItem("icon_color");
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");
      const smtp_type_id = localStorage.getItem("smtp_type_id");
      const imap_type_id = localStorage.getItem("imap_type_id");
      const icon = localStorage.getItem("icon");

      const com_channel = {
        channel_type_id: 1,
        title:account_title,
        icon_color,
        icon:"far fa-envelope",
      };

      const account = {
              channel_type_id: 1,
              code,
              fullname,
              username,
              password,
              smtp_type_id,
              imap_type_id,
              icon,
              smtp_port:"587",
              imap_port:"993",
      }

      const code = gmail_code;

     return this.generateToken({
             com_channel, account, code
            })
              .then(() => {
                
              })
              .catch((error) => {
                return error.message;
              });
    },
  },
  mounted() {
    const query = this.$route?.query;
    const code = query.code;
    this.loadEmailAccounts(code);
    console.log(localStorage.getItem("password"));
  },
};
</script>

<style></style>
